import { createStyles, Header, Container, Anchor, Group, Title } from '@mantine/core';
import Start from '../session/start';

import ActionToggle from './action-toggle';

const HEADER_HEIGHT = 84;

export const useStyles = createStyles((theme) => ({
    inner: {
        height: HEADER_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    links: {
        paddingTop: theme.spacing.lg + 4,
        height: HEADER_HEIGHT,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    mainLinks: {
        marginRight: -theme.spacing.sm,
    },

    mainLink: {
        textTransform: 'uppercase',
        fontSize: 13,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
        padding: `8px ${theme.spacing.sm}px`,
        fontWeight: 700,
        borderBottom: '2px solid transparent',
        transition: 'border-color 100ms ease, color 100ms ease',

        '&:hover': {
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            textDecoration: 'none',
        },
    },
}));

const SiteHeader = () => {
    const { classes, cx } = useStyles();

    return (
        <Header height={HEADER_HEIGHT} mb={120}>
            <Container className={classes.inner}>
                <Title order={2}>Switchbox Pointer Tool</Title>

                <div className={classes.links}>
                    <Group spacing={0} position="right" className={classes.mainLinks}>
                        <Anchor<'a'> href="/" className={cx(classes.mainLink)}>
                            Home
                        </Anchor>

                        <Start link={true} />
                    </Group>
                </div>

                <ActionToggle />
            </Container>
        </Header>
    );
};

export default SiteHeader;
