import { useState, useEffect } from 'react';
import socketIOClient, { Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { Redirect } from 'react-router-dom';
import { Anchor, Button } from '@mantine/core';

import { endpoint } from '../../endpoint';
import { useStyles } from '../header';

type Props = {
    link?: boolean;
};

const Start = ({ link = false }: Props) => {
    const { classes, cx } = useStyles();
    const [redirect, setRediect] = useState<any>();
    let socket: Socket<DefaultEventsMap, DefaultEventsMap> = socketIOClient(endpoint);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        socket = socketIOClient(endpoint);

        socket.on('api', (data) => {
            console.log('socket.on api:');
            console.log(data);
        });

        return () => {
            socket.disconnect();
            socket = null;
        };
    }, []);

    const startSession = () => {
        socket.emit('start-session', { sessionName: null });

        socket.on('create-room', (data) => {
            setRediect(data);
        });
    };

    return (
        <>
            {link ? (
                <Anchor<'a'> onClick={startSession} className={cx(classes.mainLink)}>
                    Start Session
                </Anchor>
            ) : (
                <Button onClick={startSession}>Start Session</Button>
            )}

            {redirect ? <Redirect to={`/room/${redirect.data}`} /> : null}
        </>
    );
};

export default Start;
