export const SERVER_STATUS = 'server-status';
export const START_SESSION = 'start-session';
export const JOIN_SESSION = 'join-session';

export const CREATE_ROOM = 'create-room';
export const JOIN_ROOM = 'join-room';
export const LEAVE_ROOM = 'leave-room';
export const DISCONNECT = 'disconnect';

export const UPDATE_NAME = 'person-update-name';
export const UPDATE_ROOM = 'person-list';
export const NO_ROOM = 'no-such-room';
export const PROMOTE_USER = 'promote-user';
export const PROMOTED_USER = 'promoted-user';

// Voting
export const ROOM_SHOW_VOTES = 'room-show-votes';
export const ROOM_HIDE_VOTES = 'room-hide-votes';
export const ROOM_CLEAR_VOTES = 'room-clear-votes';
export const CAST_VOTE = 'cast-vote';

export const JOINED_ROOM = 'joined-room';

export const DEFAULT_NAME = '<Pending Name...>';

export const MAX_NAME_LENGTH = 26;
