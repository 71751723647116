import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Flex, Input } from '@mantine/core';

const Join = () => {
    const [sessionId, setSessionId] = useState('');
    const [redirect, setRedirect] = useState(false);

    const submitHandler = () => {
        setRedirect(true);
    };

    return redirect ? <Redirect to={`/room/${sessionId}`} /> : <InputSession {...{ submitHandler, sessionId, setSessionId }} />;
};

const InputSession = ({ submitHandler, sessionId, setSessionId }) => {
    const handleUpdate = (e) => setSessionId(e.target.value);

    return (
        <Flex gap="md" justify="center" align="center" direction="row" wrap="wrap">
            <Input.Wrapper label="Session Id" required>
                <Input autoFocus type="text" value={sessionId} onChange={handleUpdate} placeholder="Session Id" />
            </Input.Wrapper>

            <Button mt="xl" onClick={submitHandler}>
                Join Session
            </Button>
        </Flex>
    );
};

export default Join;
