import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import socketIOClient, { Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { IconCrown, IconUser, IconCheck, IconCopy, IconAlertCircle } from '@tabler/icons';

import { endpoint } from '../../endpoint';
import { Button, Flex, Input, Text, ThemeIcon, Title, Badge, CopyButton, ActionIcon, Tooltip, Alert, Table } from '@mantine/core';
import {
    JOIN_SESSION,
    LEAVE_ROOM,
    UPDATE_NAME,
    UPDATE_ROOM,
    NO_ROOM,
    PROMOTE_USER,
    PROMOTED_USER,
    ROOM_SHOW_VOTES,
    ROOM_HIDE_VOTES,
    ROOM_CLEAR_VOTES,
    CAST_VOTE,
    JOINED_ROOM,
} from '../../config';

const shrug = '¯\\_(ツ)_/¯';
const voteSequence = [false, '0', 0.5, 1, 2, 4, 6, 8, 12, 16, 24, 32, 40, 60, 80, 120, 160, 240, shrug, '?'];
const average = (array) => {
    if (!array || array.length === 0) {
        return 0;
    }

    const votes = array.map((value) => parseInt(value)).filter((value) => value != null && !isNaN(value) && value > 0);

    if (!votes || votes.length === 0) {
        return 0;
    }

    return votes.reduce((a, b) => a + b) / votes.length;
};

const Room = () => {
    const { roomId } = useParams();
    let socket: Socket<DefaultEventsMap, DefaultEventsMap>;

    const [currentVote, setCurrentVote] = useState<number>(-1);
    const [session, setSession] = useState<Socket<DefaultEventsMap, DefaultEventsMap>>();
    const [userId, setUserId] = useState<number>(0);
    const [users, setUsers] = useState<number[]>([]);
    const [name, setName] = useState<string>('');
    const [showVotes, setShowVotes] = useState<boolean>(false);
    const [joinedRoom, setJoinedRoom] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [leaderUser, setLeaderUser] = useState<number>();

    useEffect(() => {
        if (name === '') {
            const hasLocalName = localStorage.getItem('name');

            if (hasLocalName != null) {
                setName(hasLocalName);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        socket = socketIOClient(endpoint);

        socket.emit(JOIN_SESSION, { roomId });

        socket.on(JOINED_ROOM, (data) => {
            setUserId(data.userId);
        });

        socket.on(ROOM_SHOW_VOTES, (data) => {
            setShowVotes(true);
        });

        socket.on(ROOM_HIDE_VOTES, (data) => {
            setShowVotes(false);
        });

        socket.on(PROMOTED_USER, (data) => {
            setLeaderUser(data.id);
        });

        socket.on(UPDATE_ROOM, (data) => {
            setUsers(data.users);
        });

        socket.on(NO_ROOM, () => {
            setNotFound(true);
        });

        setSession(socket);

        return () => {
            socket.emit(LEAVE_ROOM, { roomId });
            socket.disconnect();
        };
    }, []);

    const updateName = (e) => {
        setName(e.target.value);
    };

    const submitUpdateName = (e) => {
        e.preventDefault();

        if (name.trim() === '') {
            alert('Name is required');
            e.preventDefault();
        } else {
            socket = session;

            socket.emit(UPDATE_NAME, { name });

            setJoinedRoom(true);

            localStorage.setItem('name', name);
        }

        return false;
    };

    const displayVote = (id, vote) => {
        const voteStr = (vote ?? -1).toString();

        return showVotes ? voteStr : (userId === id ? currentVote : vote) ? 'voted' : 'not voted';
    };

    const displayWaffle = (vote: number, waffled: boolean): boolean => showVotes && waffled && vote > 0;

    const promote = () => {
        socket = session;

        // eslint-disable-next-line no-restricted-globals
        const leadConfirm = confirm('Are you sure you want to take lead of this room?');
        if (leadConfirm) {
            socket.emit(PROMOTE_USER, { userId });
        }
    };

    const getAverageVote = (users) => {
        if (users) {
            const allVotes = users.map((user) => parseInt(displayVote(user.id, user.vote))).filter((x) => x && !isNaN(x));

            return average(allVotes);
        }

        return 0;
    };

    const usersList = (users) => {
        return (
            <Table mt="xl">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>
                            <Flex gap="md" justify="flex-end" align="center" direction="row" wrap="wrap">
                                {showVotes && <Badge color="indigo">Average Vote: {getAverageVote(users)}</Badge>}
                                Vote
                            </Flex>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {users ? (
                        users.map((user) => {
                            const currentUser = user.id === userId;
                            const isLeader = user.leaderUser;

                            return (
                                <tr key={user.id}>
                                    <td>
                                        <Flex gap="md" justify="flex-start" align="center" direction="row" wrap="wrap">
                                            {isLeader ? (
                                                <ThemeIcon color="green" size={24} radius="xl">
                                                    <IconCrown size={16} />
                                                </ThemeIcon>
                                            ) : (
                                                <ThemeIcon color="gray" size={24} radius="xl">
                                                    <IconUser size={16} />
                                                </ThemeIcon>
                                            )}

                                            <Text fw={currentUser ? 'bold' : 500}>{user.name}</Text>
                                        </Flex>
                                    </td>

                                    <td>
                                        <Flex gap="md" justify="flex-end" align="center" direction="row" wrap="wrap">
                                            {displayWaffle(user.vote, user.waffled) && (
                                                <Tooltip label="Waffle">
                                                    <Badge color="yellow">{shrug}</Badge>
                                                </Tooltip>
                                            )}

                                            {user.vote ? <Badge color="green">{displayVote(user.id, user.vote)}</Badge> : <Badge color="gray">?</Badge>}
                                        </Flex>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={2}>No one here...</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    };

    const nameInput = () => {
        return (
            <Flex gap="md" justify="center" align="center" direction="row" wrap="wrap">
                <Input.Wrapper label="Your Name" required>
                    <Input autoFocus type="text" value={name} onChange={updateName} maxLength={26} placeholder="name" />
                </Input.Wrapper>

                <Button mt="xl" onClick={submitUpdateName}>
                    Set Name
                </Button>
            </Flex>
        );
    };

    return notFound ? (
        <>
            <Title>Room Not Found</Title>

            <Alert icon={<IconAlertCircle size={16} />} title="Not found" color="red" radius="md" variant="outline">
                Try another session, or create one!
            </Alert>
        </>
    ) : (
        <>
            <Flex gap="md" justify="center" align="center" direction="row" wrap="wrap">
                <Title>Room - {roomId}</Title>

                {joinedRoom && (
                    <CopyButton value={window.location.href}>
                        {({ copied, copy }) => (
                            <Tooltip label={copied ? 'Copied' : 'Copy Room URL'} withArrow position="right">
                                <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                                    {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </CopyButton>
                )}
            </Flex>

            {joinedRoom && <Vote socket={session} currentVote={currentVote} setCurrentVote={setCurrentVote} leaderUser={leaderUser === userId} promote={promote} />}

            {joinedRoom ? usersList(users) : nameInput()}
        </>
    );
};

const Vote = ({ socket, currentVote, setCurrentVote, leaderUser, promote }) => {
    const castVote = (vote) => {
        setCurrentVote(vote);

        socket.emit(CAST_VOTE, { vote: vote });
    };

    const clearVotes = () => {
        socket.emit(ROOM_CLEAR_VOTES);

        setCurrentVote(-1);
    };

    const showVotes = () => {
        socket.emit(ROOM_SHOW_VOTES);
    };

    const hideVotes = () => {
        socket.emit(ROOM_HIDE_VOTES);
    };

    return (
        <>
            <div>
                {leaderUser ? (
                    <Flex mih={50} gap="md" justify="center" align="center" direction="row" wrap="wrap">
                        <Button onClick={showVotes} color="green">
                            Show Votes
                        </Button>

                        <Button onClick={hideVotes} color="gray">
                            Hide Votes
                        </Button>

                        <Button onClick={clearVotes} color="yellow">
                            Clear Votes
                        </Button>
                    </Flex>
                ) : (
                    <Button onClick={promote}>Take Lead</Button>
                )}
            </div>

            <div>
                {voteSequence.map((v) => {
                    const isCurrentVote = v === currentVote;
                    let buttonColor = v ? 'gray' : 'dark';

                    if (isCurrentVote) {
                        buttonColor = 'blue';
                    }

                    return (
                        <Button key={`key-${v.toString()}`} size="xs" mr="sm" mb="sm" color={buttonColor} onClick={() => castVote(v)}>
                            {v ? v : 'Remove Vote'}
                        </Button>
                    );
                })}
            </div>
        </>
    );
};

export { Room };
