import { useState } from 'react';
import { MantineProvider, Stack, ColorScheme, ColorSchemeProvider, Title, Container } from '@mantine/core';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import SiteHeader from './features/header';
import Start from './features/session/start';
import { Room } from './features/session/room';
import Join from './features/session/join';

const NoMatch = () => {
    return <h2>404 - Not Found</h2>;
};

const Home = () => {
    return (
        <>
            <Title>Switchbox Pointer Tool</Title>

            <Start />
        </>
    );
};

export default function App() {
    const [colorScheme, setColorScheme] = useState<ColorScheme>('dark');
    const toggleColorScheme = (value?: ColorScheme) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

    return (
        <BrowserRouter>
            <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
                <MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme }}>
                    <SiteHeader />

                    <Container size="sm" px="xs">
                        <Stack align="center">
                            <Switch>
                                <Route path="/room/:roomId" component={Room} />

                                <Route path="/join">
                                    <Join />
                                </Route>

                                <Route path="/">
                                    <Home />
                                </Route>

                                <Route component={NoMatch} />
                            </Switch>
                        </Stack>
                    </Container>
                </MantineProvider>
            </ColorSchemeProvider>
        </BrowserRouter>
    );
}
